@tailwind base;
@tailwind components;
@tailwind utilities;

/* Mobile optimizations */
@layer components {
  .service-issue-item {
    @apply flex flex-col sm:flex-row items-start sm:items-center 
           justify-between p-3 border-b border-gray-100 
           last:border-b-0;
  }

  .service-status {
    @apply flex items-center space-x-2 text-sm sm:text-base;
  }

  .timestamp {
    @apply text-xs sm:text-sm text-gray-500 mt-1 sm:mt-0;
  }
}

/* Improved touch targets for mobile */
@layer utilities {
  .touch-target {
    @apply min-h-[44px] min-w-[44px];
  }
}

/* Safe area handling */
.safe-area-bottom {
  padding-bottom: env(safe-area-inset-bottom, 1rem);
}